.new-song-modal-form {
    background-color: black;
    width: 400px;
    height: 400px;
    border-radius: 10px;
    border: rgb(248, 249, 219) solid 1px;
    font-family: Arial, Helvetica, sans-serif;
    color:  rgb(248, 249, 219);
    display: grid;
    grid-template-rows: repeat(auto-fill, 80px);
    text-align: center;
}

.input-box {
    height: 30px;
    width: 200px;
    margin-top: 10px;
    border-radius: 5px;
}

.add-button {
    color: rgb(248, 249, 219);
    background-color: black;
    width: 120px;
    height: 40px;
    border: rgb(248, 249, 219) solid 2px;
    border-radius: 3px;
    align-items: center;
    justify-content: cneter;
    text-align: center;
}
.add-buttons {
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
}

.add-button:hover {
    color: rgb(158, 18, 18);
    font-weight: bold;
}
