.like-input .false {
    color: rgb(162, 162, 162);

}
.like-input .false:hover {
    color: rgb(133, 242, 111);

}

.like-input .true {
    color: rgb(30,315,96);

}

.fa-heart {
    width: 30px;
    height: 30px;
    font-size: 30px;
}
