.signup-form-modal {
    display: flex;
    align-items: center;
    background-color: black;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
    color: rgb(248, 249, 219);
    border: 1px solid rgb(248, 249, 219);
    border-radius: 5px;
    width: 400px;
}

.signup-form {
    display: flex;
    flex-direction: column;
}

#sign-up-h1 {
    text-align: center;
    margin: 20px;
}

label {
    margin: 5px;
}
.signup-form-input {
    display: flex;
    height: 20px;
    border: 1px solid transparent;
    border-radius: 5px;
    width: 100%;
}

.signup-form-button {
    width: 100%;
    height: 30px;
    padding: 0px;
    margin-left: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 2px solid rgb(248, 249, 219);
    color: rgb(248, 249, 219);
    background-color: transparent;
}
