.delete-confirmation-form {
    background-color: black;
    width: 400px;
    height: 200px;
    border-radius: 10px;
    border:  rgb(248, 249, 219) solid 1px;
    font-family: Arial, Helvetica, sans-serif;
}

.delete-title {
    color: rgb(248, 249, 219);
    border-radius: 10px;
    text-align: center;
}

.delete-confirm {
    color: rgb(248, 249, 219);
    text-align: center;
}

.delete-button {
    color:  rgb(248, 249, 219);
    background-color: black;
    width: 100px;
    height: 30px;
    border: rgb(248, 249, 219) solid 2px;
    border-radius: 3px;
    align-items: center;
    justify-content: space-around;
}

.delete-buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 40px;
}

.delete-button:hover {
    color: rgb(158, 18, 18);
    font-weight: bold;
}
