.hidden {
    display:none;
}
.search-container {
    position:absolute;
    display: grid;
    z-index: 100;
    background-color: transparent;
    height: 500px;
    overflow-y: scroll;
    overflow-x:hidden;
}
::-webkit-scrollbar {
    width: 5px;
  }

.search-input {
    color: white;
}
.search-none {
    /* position: absolute; */
    z-index: 110;
    background-color: rgb(24, 24, 24);
    color: white;
    border: white solid 1px;
    width: 140px;
    height: 30px;
    text-align: center;
    padding-top: 15px;
    border-radius: 40px;
    overflow-y:hidden;
}

.search-card {
    background-color:rgb(90, 89, 89);
    height: 60px;
    width: 150px;
    border: 1px solid rgb(173, 173, 173);
    border-radius: 10px;
    text-align: center;
    line-height: 60px;
    overflow: hidden;
}

.search-card-title {
    background-color:rgb(48, 47, 47);
    height: 40px;
    width: 150px;
    border: 1px solid rgb(173, 173, 173);
    border-radius: 10px;
    text-align: center;
    line-height: 40px;
    overflow: hidden;
}


.search-image {
    height: 80px;
    width: 80px;
}

.search-result {
    margin-top: 50px;
    position: absolute;
    height: 200px;
    overflow-y: scroll;
}

.search-results.hover{
    cursor:pointer;
}

.fa-search {
    width: 50px;
}

.navi-drawer {
    background-color: rgb(17, 16, 16);
    border-radius: 10px;
    margin-top: 20px;
    margin-bottom: 50px;
    margin-left: 20px;
    padding-left: 15px;
    padding-top: 20px;
    width: 180px;
    height: 60%;
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
}

.search-input {
    width: 160px;
    height: 30px;
    border-radius: 20px;
    background-color: rgb(46, 46, 46);
    margin-bottom: 20px;
    border: white solid 1px;
    padding-left: 10px;
}

.create-buttons {
    margin-top: 10px;
    padding: 0px;
    width: 180px;
    height: 40px;
}
.album-list-header {
    border-bottom: rgb(255, 255, 255) solid 1px;
    width: 80%;
    padding-bottom: 10px;
    padding-left: 10px;
}
.album-image {
    width: 50px;
    height: 50px;
}

.album-list {
    width: 160px;
    height: 70px;
    text-decoration: none;
    font-size: 16px;
    color: white;
}

.album-container {
    display: flex;
    align-content: center;
}

.list-name {
    text-decoration: inherit;
    color: white;
    margin: 15px 0 15px 0;
    font-size: 16px;
    padding-left: 10px;
}

input[type=search]::-webkit-search-cancel-button {
    -webkit-appearance: searchfield-cancel-button;
    padding-right: 10px;
    font-size: large;
}
