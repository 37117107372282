.create-form {
    background-color: black;
    width: 400px;
    height: 350px;
    border-radius: 10px;
    border: rgb(248, 249, 219) solid 1px;
    font-family: Arial, Helvetica, sans-serif;
    color: rgb(248, 249, 219);
    display: grid;
    grid-template-rows: repeat(auto-fill, 70px);
    text-align: center;
    padding: 20px;
}

.create-buttons {
    display: flex;
    width:400px;
    height:70px;
}
.create-button {
    color: rgb(248, 249, 219);
    background-color: black;
    width: 100px;
    height: 30px;
    border:  rgb(248, 249, 219)solid 2px;
    border-radius: 3px;
}

.creates {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 10px;
}
.create-button:hover {
    color: rgb(158, 18, 18);
    font-weight: bold;
    border:  rgb(248, 249, 219)solid 3px;
}

.input {
    height: 30px;
    width: 200px;
    margin-top: 5px;
    border-radius: 5px;
}
