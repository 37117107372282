.detail-page {
    background-color: rgb(17, 16, 16);
    border-radius: 10px;
    margin-top: 40px;
    margin-right: 40px;
    font-family: Arial, Helvetica, sans-serif;
    padding-bottom: 10px;
    margin-bottom: 50px;
}

.header {
    display: flex;
    align-content: center;
    justify-content: flex-start;
}


.playlist-buttons {
    display: flex;
    margin-left: 50px;
}

.playlist-description::before {
    content: "・";
}

.playlist-table {
    width: 90%;
    margin: 40px;
    font-size: 18px;
}

.playlist-time {
    color:rgb(162, 162, 162)
}

.playlist-img {
    width: 300px;
    height: 300px;
    margin: 50px;
    border: white 1px solid;
    border-radius: 5px;
}

.playlist-details {
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.song-button {
    display: flex;
}
